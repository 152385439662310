//TODO
// - move search bar in map
declare global {
  interface Array<T> {
    take: (count: any) => any[]
  }
}
import React from "react"
import SearchLayoutBase, { ISearchLayout } from "./searchLayoutBase"

export default function SearchLayout(input: ISearchLayout) {
  return <SearchLayoutBase {...input} />
}
