import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/layout/layout"
import SearchLayout from "../components/maps/searchLayout"
import { KioskMessage } from "../interfaces/kiosk"
import { KioskMessagesBanner } from "./KioskMessagesBanner"

export default function Home({ pageContext, location }: any) {
  const {
    messages,
  }: {
    values: any
    tags: any
    places: any
    messages: KioskMessage[]
  } = pageContext

  const { allKiosk } = useStaticQuery(
    graphql`
      query {
        allKiosk {
          totalCount
        }
      }
    `
  )

  return (
    <Layout
      title="Stalletjes langs de weg, boerderijwinkels, streekproducten &amp; biologische kwekers"
      description={`Ontdek ${allKiosk.totalCount} stalletjes langs de weg, lokale boerderijwinkels, streekproducten en biologische kwekers met lokaal verswaar. Vind groente, (onbespoten) fruit, planten, zuivel en cadeaupakketten in jouw buurt. `}
      location={location}
    >
      <SearchLayout pageContext={pageContext} maxItems={5} noLocationBanner waitForUserLocation zoomLevelHard={10} />

      <div
        style={{
          textAlign: "center",
          margin: "2rem 0",
          color: "#333",
          fontSize: "80%",
        }}
      >
        Ontdek {allKiosk.totalCount} stalletjes langs de weg, lokale
        boerderijwinkels, streekproducten en biologische kwekers met lokaal
        verswaar. Vind groente, (onbespoten) fruit, planten, zuivel en
        cadeaupakketten in jouw buurt.{" "}
      </div>

      <KioskMessagesBanner messages={messages} />

    </Layout>
  )
}
